<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-dialog
      v-model="createDialog"
      persistent
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on: dialog }">
        <v-tooltip transition="fade-transition" top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="typeReminder == 'lead' ? 'primary' : ''"
              icon
              dark
              depressed
              v-on="{ ...dialog, ...tooltip }"
            >
              <v-icon>mdi-calendar-plus</v-icon>
            </v-btn>
          </template>
          <span>Create Reminder</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title
          class="
            headline
            primary
            white--text
            align-center
            justify-space-between
          "
        >
          Create Reminder
        </v-card-title>
        <v-card-text class="my-4">
          <v-row no-gutters>
            <template v-if="currentDate == null">
              <v-col class="mr-1">
                <ma-date-picker
                  v-model="executeDate"
                  label="Date"
                  :editable="true"
                  future
                />
              </v-col>
            </template>

            <v-col class="ml-1">
              <ma-time-picker
                :editable="true"
                v-model="executeTime"
                label="Shedule"
                ref="timepick"
              />
            </v-col>
            <!--  <v-col cols="12" sm="4" class="d-flex">
              <v-checkbox
                v-model="way"
                label="Email"
                value="EMAIL"
              ></v-checkbox>
              <v-checkbox v-model="way" label="SMS" value="SMS"></v-checkbox>
              <v-checkbox
                v-model="way"
                label="Notification"
                value="NOTIFY"
              ></v-checkbox>
            </v-col> -->
          </v-row>
          <template v-if="admins">
            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-select
                  class="mr-2"
                  :items="toType"
                  v-model="totypeSelect"
                  label="To"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col>
                <template v-if="totypeSelect == 'Employee'">
                  <v-autocomplete
                    v-model="toEmployeeId"
                    label="Employees"
                    :items="employees"
                    :loading="loadingEmployees"
                    item-text="fullname"
                    item-value="uuid"
                    autocomplete="off"
                    clearable
                    hide-details
                    outlined
                    dense
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.fullname"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="data.item.ocupation"
                          class="text-capitalize"
                          v-html="data.item.ocupation.name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </template>
                <template v-if="totypeSelect == 'Lead'">
                  <FilterByLead :name="'Lead'" @selectC="setLead($event)" />
                </template>
                <template v-if="totypeSelect == 'Client'">
                  <filter-by-clients
                    @selectC="setPatient($event)"
                    :name="'Clients'"
                  />
                </template>
              </v-col>
            </v-row>
            <template v-if="totypeSelect == 'Employee'">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-select
                    class="mr-2"
                    :items="toAbout"
                    v-model="toAboutSelect"
                    label="About"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <template v-if="toAboutSelect == 'Lead'">
                    <FilterByLead
                      :name="'Lead'"
                      @selectC="setLeadAbout($event)"
                    />
                  </template>
                  <template v-if="toAboutSelect == 'Client'">
                    <filter-by-clients
                      @selectC="setPatientAbout($event)"
                      :name="'Clients'"
                    />
                  </template>
                </v-col> </v-row
            ></template>
          </template>

          <v-row no-gutters class="mt-4">
            <v-textarea
              outlined
              row-height="20"
              auto-grow
              label="Content"
              v-model="content"
            ></v-textarea>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="cancelCreate" text color="error">close</v-btn>
          <v-btn
            :loading="creating"
            :disabled="creating || !validAdd"
            @click="confirmCreateReminder"
            text
            color="success"
            >create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifySuccess } from "@/components/Notification";
import { mapActions, mapGetters, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import MaTimePicker from "@/components/MaTimePicker.vue";
import FilterByClients from "@/components/filters/FilterByClients.vue";

const FilterByLead = () => import("@/components/filters/FilterByLead.vue");
export default {
  props: {
    external: {
      type: Boolean,
      default: () => false,
    },
    typeReminder: {
      type: String,
      default: () => "calendar",
    },
    uuid: {
      type: String,
      default: () => "",
    },
  },
  components: { MaDatePicker, MaTimePicker, FilterByClients, FilterByLead },
  data() {
    return {
      createDialog: false,
      currentDate: null,
      creating: false,
      executeTime: null,
      executeDate: null,
      content: null,
      toEmployeeId: null,
      admins: false,
      toLeadId: null,
      toPatientId: null,
      aboutLeadId: null,
      aboutPatientId: null,
      way: ["NOTIFY"],
      totypeSelect: "none",
      toAboutSelect: "none",
      toType: ["Employee", "Lead", "Client"],
      toAbout: ["MySelf", "Lead", "Client"],
    };
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "isSupervisor",
      "getpatientN",
      "getUuid",
    ]),

    ...mapState("crmConfigurationsModule", ["leads"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    leadshow() {
      if (this.leads.length == 0) {
        return [];
      }
      const arr = this.leads.map((l) => {
        return { ...l, fullname: l.name + " " + l.last_name };
      });
      return arr;
    },
    validEmploye() {
      if (this.totypeSelect == "Employee") {
        if (this.toEmployeeId == null || this.toEmployeeId == "") {
          return false;
        }
        return true;
      }
      return true;
    },
    validLead() {
      if (this.totypeSelect == "Lead") {
        if (this.toLeadId == null || this.toLeadId == "") {
          return false;
        }
        return true;
      }
      return true;
    },
    validLeadAbout() {
      if (this.toAboutSelect == "Lead") {
        if (this.aboutLeadId == null || this.aboutLeadId == "") {
          return false;
        }
        return true;
      }
      return true;
    },
    validPatient() {
      if (this.totypeSelect == "Client") {
        if (this.toPatientId == null || this.toPatientId == "") {
          return false;
        }
        return true;
      }
      return true;
    },
    validPatientAbout() {
      if (this.toAboutSelect == "Client") {
        if (this.aboutPatientId == null || this.aboutPatientId == "") {
          return false;
        }
        return true;
      }
      return true;
    },

    validAdd() {
      if (
        this.executeTime != null &&
        this.executeDate != null &&
        this.way.length != 0 &&
        this.content != null &&
        this.content != "" &&
        this.validPatient &&
        this.validEmploye &&
        this.validLead &&
        this.validLeadAbout &&
        this.validPatientAbout
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapActions("crmConfigurationsModule", ["actGetLeads"]),
    setPatient(val) {
      this.toPatientId = val;
      this.toEmployeeId = null;
      this.toLeadId = null;
    },
    setLead(val) {
      this.toLeadId = val;
      this.toPatientId = null;
      this.toEmployeeId = null;
    },
    setPatientAbout(val) {
      this.aboutPatientId = val;
      this.aboutLeadId = null;
    },
    setLeadAbout(val) {
      this.aboutLeadId = val;
      this.aboutPatientId = null;
    },
    confirmCreateReminder() {
      const date = moment(this.executeDate).format("MM-DD-YYYY");
      const time = this.executeTime;
      const body = {
        content: this.content,
        way: this.way,
        executeAt: moment(`${date} ${time}`).utc().toISOString(),
        toEmployeeId: this.toEmployeeId,
        toLeadId: this.toLeadId,
        toPatientId: this.toPatientId,
        aboutLeadId: this.aboutLeadId,
        aboutPatientId: this.aboutPatientId,
      };

      if (this.totypeSelect != "Client") {
        delete body.toPatientId;
      }
      if (this.totypeSelect != "Employee") {
        delete body.toEmployeeId;
      }

      if (this.totypeSelect != "Lead") {
        delete body.toLeadId;
      }
      if (!this.external) {
        if (this.toAboutSelect != "Lead") {
          delete body.aboutLeadId;
        }
        if (this.toAboutSelect != "Client") {
          delete body.aboutPatientId;
        }
      } else {
        if (this.typeReminder != "lead") {
          delete body.aboutLeadId;
        }
        if (this.typeReminder != "patient") {
          delete body.aboutPatientId;
        }
      }
      this.creating = true;

      getAPI
        .post("/reminder/create", body)
        .then(() => {
          this.creating = false;
          notifySuccess("Reminder create successfully");

          this.cancelCreate();
          this.$emit("updateReminder");
        })
        .catch((error) => {
          this.creating = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelCreate() {
      this.createDialog = false;
      this.currentDate = null;
      this.executeTime = null;
      this.executeDate = null;
      this.content = null;
      this.toEmployeeId = null;
      this.toLeadId = null;
      this.toPatientId = null;
      this.aboutLeadId = null;
      this.aboutPatientId = null;
      this.creating = false;
      this.way = ["NOTIFY"];
      this.totypeSelect = "none";
      this.toAboutSelect = "none";
    },
  },
  async mounted() {
    if (this.typeReminder == "calendar") {
      await this.actGetLeads({ interval: { limit: 1000, offset: 0 } });

      if (this.isAdmin || this.isSuper || this.isSupervisor) {
        this.admins = true;

        await this.actGetEmployees();
      } else {
        this.totypeSelect = "Employee";
        this.toEmployeeId = this.getUuid;
      }
    } else if (this.typeReminder == "lead") {
      this.aboutLeadId = this.uuid;
    } else {
      this.aboutPatientId = this.uuid;
    }
  },
};
</script>
<style lang="scss"></style>
