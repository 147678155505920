import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";

export interface MonthItem {
  abbreviation: string;
  name: TranslateResult;
}

export const monthsNames: MonthItem[] = [
  {
    abbreviation: "Jan",
    name: i18n.t("January")
  },
  {
    abbreviation: "Feb",
    name: i18n.t("February")
  },
  {
    abbreviation: "Mar",
    name: i18n.t("March")
  },
  {
    abbreviation: "Apr",
    name: i18n.t("April")
  },
  {
    abbreviation: "May",
    name: i18n.t("May")
  },
  {
    abbreviation: "Jun",
    name: i18n.t("June")
  },
  {
    abbreviation: "Jul",
    name: i18n.t("July")
  },
  {
    abbreviation: "Aug",
    name: i18n.t("August")
  },
  {
    abbreviation: "Sep",
    name: i18n.t("September")
  },
  {
    abbreviation: "Oct",
    name: i18n.t("October")
  },
  {
    abbreviation: "Nov",
    name: i18n.t("November")
  },
  {
    abbreviation: "Dec",
    name: i18n.t("December")
  }
];
